import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import "../App.css";
import "react-toastify/dist/ReactToastify.css";

import BetDetails from "../Layouts/BetDetails";
import MainLayout from "../Layouts/MainLayout";
// import CashTransaction from "../Pages/CashTransaction";
import Casino from "../Pages/Casino";
import CasinoBets from "../Pages/CasinoBets";
import ClientPlusMinus from "../Pages/ClientPlusMinus";
import CompleteGames from "../Pages/CompleteGames";
// import CreateUser from "../Pages/CreateUser";
import RecycleInplay from "../Pages/Recyclebets/RecycleInplay";
import RecycleComplete from "../Pages/Recyclebets/RecycleComplete";
import RecycleMatch from "../Pages/Recyclebets/RecycleMatch";
import RecycleSession from "../Pages/Recyclebets/RecycleSession";
import Dashboard from "../Pages/Dashboard";
import InPlay from "../Pages/InPlay";
import Ledger from "../Pages/Ledger";
import LiveReport from "../Pages/LiveReport";
import Login from "../Pages/Login";
import MatchAndSessionPM from "../Pages/MatchAndSessionPM";
import MatchBetDetails from "../Pages/MatchBetDetails";
import Reports from "../Pages/Reports";
import SessionBetDetails from "../Pages/SessionBetDetails";
import SessionPMList from "../Pages/SessionPMList";
///Power Users routes
import PowerUsers from "../Pages/PowerUsers/PowerUsers";
import CreatePowerUsers from "../Pages/PowerUsers/CreatePowerUsers";
import UpdatePowerUsers from "../Pages/PowerUsers/UpdatePowerUsers";
///Admin route
import AdminList from "../Pages/Admin/AdminList";
import CreateNewAdmin from "../Pages/Admin/CreateNewAdmin";
import UpdateAdmin from "../Pages/Admin/UpdateAdmin";
import AdminLimitUpdate from "../Pages/Admin/AdminLimitUpdate";
///subadmin route
import SubadminList from "../Pages/Subadmin/SubadminList";
import CreateNewSubadmin from "../Pages/Subadmin/CreateNewSubadmin";
import UpdateSubadmin from "../Pages/Subadmin/UpdateSubadmin";
import SubadminLimitUpdate from "../Pages/Subadmin/SubadminLimitUpdate";
import SubAdminChildLimit from "../Pages/Subadmin/SubAdminChildLimit";
//Super Master routes
import SuperMasterList from "../Pages/SuperMaster/SuperMasterList";
import CreateNewSuperMaster from "../Pages/SuperMaster/CreateNewSuperMaster";
import UpdateSuperMaster from "../Pages/SuperMaster/UpdateSuperMaster";
import SuperMasterLimitUpdate from "../Pages/SuperMaster/SuperMasterLimitUpdate";
import SuperMasterChildLimit from "../Pages/SuperMaster/SuperMasterChildLimit";
//Master routes
import MasterList from "../Pages/Master/MasterList";
import CreateNewMaster from "../Pages/Master/CreateNewMaster";
import UpdateMaster from "../Pages/Master/UpdateMaster";
import MasterLimitUpdate from "../Pages/Master/MasterLimitUpdate";
import MasterChildLimit from "../Pages/Master/MasterChildLimit";
// import ClientMasterLimitUpdate from "../Pages/Master/ClientMasterLimitUpdate";
//Super Agent Routes
import SuperAgentList from "../Pages/SuperAgent/SuperAgentList";
import UpdateSuperAgent from "../Pages/SuperAgent/UpdateSuperAgent";
import CreateNewSuperAgent from "../Pages/SuperAgent/CreateNewSuperAgent";
import SuperAgentLimitUpdate from "../Pages/SuperAgent/SuperAgentLimitUpdate";
import SuperAgentChildLimit from "../Pages/SuperAgent/SuperAgentChildLimit";
//Agent routes
import AgentMaster from "../Pages/AgentMaster/AgentMaster";
import CreateNewAgent from "../Pages/AgentMaster/CreateNewAgent";
import AgentMasterLimitUpdate from "../Pages/AgentMaster/AgentMasterLimitUpdate";
import UpdateAgent from "../Pages/AgentMaster/UpdateAgent";
import AgentMasterChildLimit from "../Pages/AgentMaster/AgentMasterChildLimit";
//Clint routes
import ClientMasterList from "../Pages/ClientMaster/ClientMasterList";
import CreateNewClientMaster from "../Pages/ClientMaster/CreateNewClientMaster";
import UpdateClient from "../Pages/ClientMaster/UpdateClient";
import ClientMasterLimitUpdate from "../Pages/ClientMaster/ClientMasterLimitUpdate";

// import CreateNewClientMaster from "../Pages/ClientMaster/CreateNewClientMaster";
import AgentLedger from "../Pages/LedgerManagement/AgentLedger";
import ClientLedger from "../Pages/LedgerManagement/ClientLedger";
import MasterLedger from "../Pages/LedgerManagement/MasterLedger";
import SuperAgentLedger from "../Pages/LedgerManagement/SuperAgentLedger";
import SubAdminLedger from "../Pages/LedgerManagement/SubAdminLedger";
import MyLedger from "../Pages/LedgerManagement/MyLedger";

import ClientLoginReport from "../Pages/LoginReports/ClientLoginReport";
import AgentLoginReport from "../Pages/LoginReports/AgentLoginReport";
import SuperAgentLoginReport from "../Pages/LoginReports/SuperAgentLoginReport";
import MasterLoginReport from "../Pages/LoginReports/MasterLoginReport";
import SubAdminLoginReport from "../Pages/LoginReports/SubAdminLoginReport";
import SuperAdminLoginReport from "../Pages/LoginReports/SuperAdminLoginReport";

import ClientDataReport from "../Pages/DataReports/ClientDataReport";
import AgentDataReport from "../Pages/DataReports/AgentDataReport";
import SuperAgentDataReport from "../Pages/DataReports/SuperAgentDataReport";
import MasterDataReport from "../Pages/DataReports/MasterDataReport";
import SubAdminDataReport from "../Pages/DataReports/SubAdminDataReport";
import SuperAdminDataReport from "../Pages/DataReports/SuperAdminDataReport";

import ClientCashTransaction from "../Pages/CashTransaction/ClientCashTransaction";
import AgentCashTransaction from "../Pages/CashTransaction/AgentCashTransaction";
import MasterCashTransaction from "../Pages/CashTransaction/MasterCashTransaction";
import SuperAgentCashTransaction from "../Pages/CashTransaction/SuperAgentCashTransaction";
import SubAdminCashTransaction from "../Pages/CashTransaction/SubAdminCashTransaction";
import SuperAdminCashTransaction from "../Pages/CashTransaction/SuperAdminCashTransaction";
///////////////control panel ////////
import ControlLogin from "../ControlPanel/ControlLogin";
// import PrivateRoute from "../PrivateRoute";
import AllMatches from "../ControlPanel/AllMatches";
import SessionBetLogs from "../Pages/SessionBetLogs";

///////////////user control panel ////////

// import ClientLimitUpdate from "../Pages/UpdateLimit/ClientLimitUpdate";
import InPlayList from "../ControlPanel/InPlay";
import SessionInplay from "../ControlPanel/SessionInplay";
import SessionMinMaxLimit from "../ControlPanel/SessionMinMaxLimit";
import SessionDesscion from "../ControlPanel/SessionDesscion";
import ClickSingleSessionDesscion from "../ControlPanel/ClickSingleSessionDesscion";
import RepeatSessionDesscion from "../ControlPanel/RepeatSessionDesscion";
import InternalRepeatSession from "../ControlPanel/InternalRepeatSession";
import MatchDesscion from "../ControlPanel/MatchDesscion";
import CompleteSports from "../ControlPanel/CompleteSports";
import ViewDesicion from "../ControlPanel/ViewDesicion";
import AddMatchDescription from "../ControlPanel/AddMatchDescription";
import EditMatchDescription from "../ControlPanel/EditMatchDescription";

import NotFound404 from "../Components/NotFound404";
import Profile from "../Pages/Profile";
import ChangePassword from "../Pages/ChangePassword";
import InPlayEdit from "../Pages/InplaySubPages/InplayEdit";
import InPlayNewGame from "../Pages/InplaySubPages/InplayNewGame";
import InplayControlGame from "../Pages/InplaySubPages/InplayControlGame";
import InplayDecisionGame from "../Pages/InplaySubPages/InplayDecisionGame";
import InplayLongTable from "../Pages/InplaySubPages/InplayLongTable";
import InplayMatchBets from "../Pages/InplaySubPages/InplayMatchBets";
import InplayMatchSessionPosition from "../Pages/InplaySubPages/InplayMatchSessionPosition";
import InplayMatchSessionPlusMinus from "../Pages/InplaySubPages/InplayMatchSessionPlusMinus";
import InplaySessionListPlusMinus from "../Pages/InplaySubPages/InplaySessionListPlusMinus";
import InplayMatchSessionBets from "../Pages/InplaySubPages/InplayMatchSessionBets";
import InplaySessionBets from "../Pages/InplaySubPages/InplaySessionBets";
import InplaySessionBetsEdit from "../Pages/InplaySubPages/InplaySessionBetsEdit";
import InplayAddGame from "../Pages/InplaySubPages/InplayAddGame";

import CompleteMatchSessionBets from "../Pages/CompleteSubPages/CompleteMatchSessionBets";
import CompleteMatchBets from "../Pages/CompleteSubPages/CompleteMatchBets";
import CompleteSessionBets from "../Pages/CompleteSubPages/CompleteSessionBets";
import CompleteMatchSessionPlusMinus from "../Pages/CompleteSubPages/CompleteMatchSessionPlusMinus";
import CompleteSessionListPlusMinus from "../Pages/CompleteSubPages/CompleteSessionListPlusMinus";
import CompleteLongTable from "../Pages/CompleteSubPages/CompleteLongTable";

import CollectionList from "../Pages/Collection/CollectionList";
import CreateNewCollection from "../Pages/Collection/CreateNewCollection";
import VirtualTeenPati from "../Pages/Casino/VirtualTeenPati";
import LiveTeenPati from "../Pages/Casino/LiveTeenPati";
import CasinoDetails from "../Pages/Casino/CasinoDetails";
import CasinoBetReport from "../Pages/Casino/CasinoBetReport";
import CasinoPlusMinusReport from "../Pages/Casino/CasinoPlusMinusReport";
import CasinoPlusMinusTable from "../Pages/Casino/CasinoPlusMinusTable";
import CasinoInPlay from "../Pages/Casino/CasinoInPlay";
import CasinoAddButton from "../Pages/Casino/CasinoAddButton";
import TotalComission from "../Pages/TotalComission";
import ClientTotalComission from "../Pages/TotalCommission/ClientTotalCommission";

const AppRoutes = () => {
  const navigate = useNavigate();

  const renderRoute = (element) => {
    if (localStorage.getItem("token") && localStorage.getItem("status")) {
      return element;
    } else {
      if (window.location.pathname !== "/") {
        localStorage.clear();
        navigate("/");
        // return <Navigate to="/" />;
      }
    }
  };

  return (
    <Routes>
      {/* ////////control panel //////////// */}
      <Route exact path="/super-admin/login" element={<ControlLogin />} />
      <Route
        exact
        path="/super-admin/all-matches"
        element={renderRoute(<AllMatches />)}
      />
      <Route
        exact
        path="/super-admin/inplay-list"
        element={renderRoute(<InPlayList />)}
      />
      <Route
        exact
        path="/session_inplay/:gameId"
        element={renderRoute(<SessionInplay />)}
      />
      <Route
        exact
        path="/super-admin/Session-Min-Max-Limit"
        element={renderRoute(<SessionMinMaxLimit />)}
      />
      <Route
        exact
        path="/super-admin/session-desscion"
        element={renderRoute(<SessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/session-desscion/clickDesscion"
        element={renderRoute(<ClickSingleSessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/repeat-session-desscion"
        element={renderRoute(<RepeatSessionDesscion />)}
      />
      <Route
        exact
        path="/super-admin/repeat-session-desscion/InternalRepeatSession"
        element={renderRoute(<InternalRepeatSession />)}
      />
      <Route
        exact
        path="/super-admin/match-desscion"
        element={renderRoute(<MatchDesscion />)}
      />
      <Route
        exact
        path="/super-admin/complete-sports"
        element={renderRoute(<CompleteSports />)}
      />
      <Route
        exact
        path="/super-admin/view-desicion/:matchId"
        element={renderRoute(<ViewDesicion />)}
      />
      <Route
        exact
        path="/super-admin/add-match-description"
        element={renderRoute(<AddMatchDescription />)}
      />
      <Route
        exact
        path="/super-admin/add-match-description/EditMatchDesctiption"
        element={renderRoute(<EditMatchDescription />)}
      />
      {/* ////////end control panel //////////// */}
      <Route exact path="/" element={<Login />} />
      <Route exact path="/" element={renderRoute(<MainLayout />)}>
        <Route exact path="/profile" element={renderRoute(<Profile />)} />
        <Route
          exact
          path="/change-password"
          element={renderRoute(<ChangePassword />)}
        />

        <Route
          exact
          path="/dashboard"
          index
          element={renderRoute(<Dashboard />)}
        />
        {/* Power User */}
        <Route
          exact
          path="/power-users"
          index
          element={renderRoute(
            <PowerUsers
              title="Power Users"
              roleprops="powerUsers"
              type="powerUsers"
            />
          )}
        />
        <Route
          exact
          path="/power-users/create"
          element={renderRoute(<CreatePowerUsers />)}
        />

        <Route
          exact
          path="/power-users/update"
          element={renderRoute(<UpdatePowerUsers />)}
        />
        {/* adminList */}
        <Route
          exact
          path="/collection-list"
          element={renderRoute(<CollectionList />)}
        />

        <Route
          exact
          path="/New-collection"
          element={renderRoute(<CreateNewCollection />)}
        />

        <Route
          exact
          path="/total-comission"
          element={renderRoute(<TotalComission />)}
        />

        <Route
          exact
          path="/client-total-comission"
          element={renderRoute(<ClientTotalComission />)}
        />

        <Route
          exact
          path="/admin"
          index
          element={renderRoute(
            <AdminList
              title="Admin"
              clickst="shoot"
              roleprops="admin"
              type="admin"
            />
          )}
        />
        <Route
          exact
          path="/admin/create"
          element={renderRoute(<CreateNewAdmin />)}
        />
        <Route
          exact
          path="/admin/update"
          element={renderRoute(<UpdateAdmin />)}
        />
        <Route
          exact
          path="/admin/limitUpdate"
          element={renderRoute(<AdminLimitUpdate />)}
        />

        {/* SubadminList */}
        <Route
          exact
          path="/sub-admin"
          index
          element={renderRoute(
            <SubadminList
              title="Sub Admin Master"
              clickst="shoot"
              roleprops="subadmin"
              type="subadmin"
            />
          )}
        />
        <Route
          exact
          path="/sub-admin/create"
          element={renderRoute(<CreateNewSubadmin />)}
        />
        <Route
          exact
          path="/sub-admin/update"
          element={renderRoute(<UpdateSubadmin />)}
        />
        <Route
          exact
          path="/sub-admin/limitUpdate"
          element={renderRoute(<SubadminLimitUpdate />)}
        />
        <Route
          exact
          path="/sub-admin/child-limitUpdate"
          element={renderRoute(<SubAdminChildLimit />)}
        />
        {/* Super MasterList */}
        <Route
          exact
          path="/supermaster"
          index
          element={renderRoute(
            <SuperMasterList
              title="Super Master Details"
              roleprops="supermaster"
              type="supermaster"
            />
          )}
        />
        <Route
          exact
          path="/supermaster/create"
          element={renderRoute(<CreateNewSuperMaster />)}
        />
        <Route
          exact
          path="/supermaster/update"
          element={renderRoute(<UpdateSuperMaster />)}
        />
        <Route
          exact
          path="/supermaster/limitUpdate"
          element={renderRoute(<SuperMasterLimitUpdate />)}
        />
        <Route
          exact
          path="/supermaster/child-limitUpdate"
          element={renderRoute(<SuperMasterChildLimit />)}
        />

        {/* MasterList */}
        <Route
          exact
          path="/masters"
          index
          element={renderRoute(
            <MasterList
              title="Master Details"
              roleprops="master"
              type="master"
            />
          )}
        />
        <Route
          exact
          path="/masters/create"
          element={renderRoute(<CreateNewMaster />)}
        />
        <Route
          exact
          path="/masters/update"
          element={renderRoute(<UpdateMaster />)}
        />
        <Route
          exact
          path="/masters/limitUpdate"
          element={renderRoute(<MasterLimitUpdate />)}
        />
        <Route
          exact
          path="/masters/child-limitUpdate"
          element={renderRoute(<MasterChildLimit />)}
        />
        {/* SuperAgenetList */}
        <Route
          exact
          path="/superagents"
          index
          element={renderRoute(
            <SuperAgentList
              title="Superagent Details"
              roleprops="superagents"
              type="superagents"
            />
          )}
        />
        <Route
          exact
          path="/superagents/create"
          element={renderRoute(<CreateNewSuperAgent />)}
        />
        <Route
          exact
          path="/superagents/update"
          element={renderRoute(<UpdateSuperAgent />)}
        />
        <Route
          exact
          path="/superagents/limitUpdate"
          element={renderRoute(<SuperAgentLimitUpdate />)}
        />
        <Route
          exact
          path="/superagents/child-limitUpdate"
          element={renderRoute(<SuperAgentChildLimit />)}
        />
        {/* AgentMaster */}
        <Route
          exact
          path="/agent"
          index
          element={renderRoute(
            <AgentMaster
              title="Agent Details"
              roleprops="agents"
              type="agent"
            />
          )}
        />
        <Route
          exact
          path="/agent/create"
          element={renderRoute(<CreateNewAgent />)}
        />
        <Route
          exact
          path="/agent/update"
          element={renderRoute(<UpdateAgent />)}
        />
        <Route
          exact
          path="/agent/limitUpdate"
          element={renderRoute(<AgentMasterLimitUpdate />)}
        />
        <Route
          exact
          path="/agent/child-limitUpdate"
          element={renderRoute(<AgentMasterChildLimit />)}
        />
        {/* ClientMasterlist */}
        <Route
          exact
          path="/client"
          index
          element={renderRoute(
            <ClientMasterList title="Client" roleprops="client" type="client" />
          )}
        />
        <Route
          exact
          path="/client/create"
          element={renderRoute(<CreateNewClientMaster />)}
        />
        <Route
          exact
          path="/client/update"
          element={renderRoute(<UpdateClient />)}
        />
        <Route
          exact
          path="/client/limitUpdate"
          element={renderRoute(<ClientMasterLimitUpdate />)}
        />

        <Route exact path="/inplay" index element={renderRoute(<InPlay />)} />
        <Route
          exact
          path="/recyclesession"
          index
          element={renderRoute(<RecycleSession />)}
        />
        <Route
          exact
          path="/recyclematch"
          index
          element={renderRoute(<RecycleMatch />)}
        />

        <Route
          exact
          path="/recycle-inplay"
          index
          element={renderRoute(<RecycleInplay />)}
        />

        <Route
          exact
          path="/recycle-complete"
          index
          element={renderRoute(<RecycleComplete />)}
        />

        <Route
          exact
          path="/inplay/edit-game"
          element={renderRoute(<InPlayEdit />)}
        />

        <Route
          exact
          path="/inplay/add-game"
          element={renderRoute(<InPlayNewGame />)}
        />

        <Route
          exact
          path="/inplay/add-game-api"
          element={renderRoute(<InplayAddGame />)}
        />

        <Route
          exact
          path="/inplay/control-game"
          element={renderRoute(<InplayControlGame />)}
        />

        <Route
          exact
          path="/inplay/decision-game"
          element={renderRoute(<InplayDecisionGame />)}
        />

        <Route
          exact
          path="/inplay-long-table"
          element={renderRoute(<InplayLongTable />)}
        />

        <Route
          exact
          path="/inplay/match-bets"
          element={renderRoute(<InplayMatchBets />)}
        />

        <Route
          exact
          path="/inplay/match-session-position"
          element={renderRoute(<InplayMatchSessionPosition />)}
        />

        <Route
          exact
          path="/inplay/match-session-plus-minus"
          element={renderRoute(<InplayMatchSessionPlusMinus />)}
        />

        <Route
          exact
          path="/inplay/session-list-plus-minus"
          element={renderRoute(<InplaySessionListPlusMinus />)}
        />

        <Route
          exact
          path="/inplay/match-session-bets"
          element={renderRoute(<InplayMatchSessionBets />)}
        />

        <Route
          exact
          path="/inplay/session-bets"
          element={renderRoute(<InplaySessionBets />)}
        />

        <Route
          exact
          path="/inplay/session-bets/edit"
          element={renderRoute(<InplaySessionBetsEdit />)}
        />

        <Route
          exact
          path="/session-bet-logs"
          element={renderRoute(<SessionBetLogs />)}
        />

        <Route
          exact
          path="/complete-games"
          index
          element={renderRoute(<CompleteGames />)}
        />

        <Route
          exact
          path="/complete-games/match-session-bets"
          element={renderRoute(<CompleteMatchSessionBets />)}
        />

        <Route
          exact
          path="/complete-games/match-bets"
          element={renderRoute(<CompleteMatchBets />)}
        />

        <Route
          exact
          path="/complete-games/session-bets"
          element={renderRoute(<CompleteSessionBets />)}
        />

        <Route
          exact
          path="/complete-games/match-session-plus-minus"
          element={renderRoute(<CompleteMatchSessionPlusMinus />)}
        />

        <Route
          exact
          path="/complete-games/session-list-plus-minus"
          element={renderRoute(<CompleteSessionListPlusMinus />)}
        />

        <Route
          exact
          path="/complete-games/all-company-report"
          element={renderRoute(<CompleteLongTable />)}
        />

        <Route
          exact
          path="/live-report"
          element={renderRoute(<LiveReport />)}
        />
        <Route
          exact
          path="/match-session-pm"
          element={renderRoute(<MatchAndSessionPM />)}
        />
        <Route
          exact
          path="/session-pm-list"
          element={renderRoute(<SessionPMList />)}
        />
        <Route
          exact
          path="/bet-details"
          element={renderRoute(<BetDetails />)}
        />
        <Route
          exact
          path="/match-bet-details"
          element={renderRoute(<MatchBetDetails />)}
        />
        <Route
          exact
          path="/ses+sion-bet-details"
          element={renderRoute(<SessionBetDetails />)}
        />

        <Route
          exact
          path="/teenpatti_t20"
          element={renderRoute(<Casino game_type="TEENPATTI T20" />)}
        />
        <Route
          exact
          path="/dragon_tiger"
          element={renderRoute(<Casino game_type="DRAGON TIGER" />)}
        />
        <Route
          exact
          path="/lucky7_a"
          element={renderRoute(<Casino game_type="LUCKY7 A" />)}
        />
        <Route
          exact
          path="/casino-bets"
          element={renderRoute(<CasinoBets />)}
        />

        <Route
          exact
          path="/virtual-teen-patti"
          element={renderRoute(<VirtualTeenPati />)}
        />

        <Route
          exact
          path="/live-teen-patti"
          element={renderRoute(<LiveTeenPati />)}
        />

        <Route
          exact
          path="/casino-details"
          element={renderRoute(<CasinoDetails />)}
        />

        <Route
          exact
          path="/casino-bet-report"
          element={renderRoute(<CasinoBetReport />)}
        />

        <Route
          exact
          path="/casino-plus-minus-report"
          element={renderRoute(<CasinoPlusMinusReport />)}
        />

        <Route
          exact
          path="/casino-plus-minus-table"
          element={renderRoute(<CasinoPlusMinusTable />)}
        />

        <Route
          exact
          path="/casino-in-play"
          element={renderRoute(<CasinoInPlay />)}
        />

        <Route
          exact
          path="/casino-add-button"
          element={renderRoute(<CasinoAddButton />)}
        />

        {/* <Route exact path="/"> */}
        <Route
          exact
          path="/transactions"
          element={renderRoute(<ClientCashTransaction user="Client" />)}
        />
        <Route
          exact
          path="/agent-transactions"
          element={renderRoute(<AgentCashTransaction user="Agent" />)}
        />
        <Route
          exact
          path="/superagent-transactions"
          element={renderRoute(
            <SuperAgentCashTransaction user="Super Agent" />
          )}
        />
        <Route
          exact
          path="/master-transactions"
          element={renderRoute(<MasterCashTransaction user="Master" />)}
        />
        <Route
          exact
          path="/subadmin-transactions"
          element={renderRoute(<SubAdminCashTransaction user="Sub Admin" />)}
        />
        <Route
          exact
          path="/admin-transactions"
          element={renderRoute(
            <SuperAdminCashTransaction user="Super Admin" />
          )}
        />
        {/* </Route > */}

        <Route path="/my-ledger" element={renderRoute(<MyLedger />)} />
        <Route
          path="/client-plus-minus"
          element={renderRoute(<ClientPlusMinus />)}
        />
        <Route
          path="/client-ledger"
          element={renderRoute(
            <ClientLedger ledger_type="client" user="Client" />
          )}
        />
        <Route
          path="/agent-ledger"
          element={renderRoute(
            <AgentLedger
              ledger_type="agent"
              api="all-agent-ledger"
              user="Agent"
            />
          )}
        />
        <Route
          path="/super-ledger"
          element={renderRoute(
            <SuperAgentLedger
              ledger_type="super"
              api="all-superagent-ledger"
              user="Super"
            />
          )}
        />
        <Route
          path="/master-ledger"
          element={renderRoute(
            <MasterLedger
              ledger_type="master"
              api="all-master-ledger"
              user="Master"
            />
          )}
        />
        <Route
          path="/sub-admin-ledger"
          element={renderRoute(
            <SubAdminLedger
              ledger_type="sub-admin"
              api="all-sub-admin-ledger"
              user="Sub Admin"
            />
          )}
        />
        <Route
          path="/admin-ledger"
          element={renderRoute(<Ledger ledger_type="admin" user="Admin" />)}
        />

        {/* <Route path="/"> */}
        <Route
          path="/admin-report"
          element={renderRoute(<Reports user="Admin" />)}
        />
        <Route
          path="/master-report"
          element={renderRoute(<Reports user="Master" />)}
        />
        <Route
          path="/super-report"
          element={renderRoute(<Reports user="Super" />)}
        />
        <Route
          path="/agent-report"
          element={renderRoute(<Reports user="Agent" />)}
        />
        <Route
          path="/client-report"
          element={renderRoute(<Reports user="Client" />)}
        />
        {/* </Route > */}
        {/* common login Report   */}
        {/* <Route
              path="/login-report"
              element={renderRoute(<LoginReport user="Admin" />)}
            /> */}
        {/* <Route path="/"> */}
        {/* <Route
              path="/admin-login-report"
              element={renderRoute(<LoginReport user="Admin" />)}
            /> */}
        <Route
          path="/super-admin-login-report"
          element={renderRoute(<SuperAdminLoginReport user="Super Admin" />)}
        />
        <Route
          path="/sub-admin-login-report"
          element={renderRoute(<SubAdminLoginReport user="Sub Admin" />)}
        />
        <Route
          path="/master-login-report"
          element={renderRoute(<MasterLoginReport user="Master" />)}
        />
        <Route
          path="/super-agent-login-report"
          element={renderRoute(<SuperAgentLoginReport user="Super Agent" />)}
        />
        <Route
          path="/agent-login-report"
          element={renderRoute(<AgentLoginReport user="Agent" />)}
        />
        <Route
          path="/client-login-report"
          element={renderRoute(<ClientLoginReport user="Client" />)}
        />

        <Route
          path="/super-admin-data-report"
          element={renderRoute(<SuperAdminDataReport user="Super Admin" />)}
        />
        <Route
          path="/sub-admin-data-report"
          element={renderRoute(<SubAdminDataReport user="Sub Admin" />)}
        />
        <Route
          path="/master-data-report"
          element={renderRoute(<MasterDataReport user="Master" />)}
        />
        <Route
          path="/super-agent-data-report"
          element={renderRoute(<SuperAgentDataReport user="Super Agent" />)}
        />
        <Route
          path="/agent-data-report"
          element={renderRoute(<AgentDataReport user="Agent" />)}
        />
        <Route
          path="/client-data-report"
          element={renderRoute(<ClientDataReport user="Client" />)}
        />
      </Route>
      <Route exact path="*" element={<NotFound404 />} />
    </Routes>
  );
};

export default AppRoutes;
