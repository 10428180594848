/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import { passwordGenerator } from "../../utils/passwordGenerator";
import useGetAccount from "../../hooks/useGetAccount";
import useUpdateAccount from "../../hooks/useUpdateAccount";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function UpdateClient() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const navigate = useNavigate();

  const userPassword = localStorage.getItem("password");

  const location = useLocation();
  const userData = location.state;
  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: getAccount } = useGetAccount();
  const { mutate: updateAccount } = useUpdateAccount();

  const [parentData, setParentData] = useState([]);

  const [clientName, setClientName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [clientLimit, setClientLimit] = useState(0);
  const [clientMobileShare, setClientMobileShare] = useState(0);
  const [clientCommission, setClientCommission] = useState("");
  const [clientMatchCommission, setClientMatchCommission] = useState(0);
  const [clientSessionCommission, setClientSessionCommission] = useState(0);
  const [clientCassinoCommission, setClientCassinoCommission] = useState(0);
  const [clientCassinoStatus, setClientCassinoStatus] = useState(false);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(userData);

    setClientName(userData.name);
    setClientNumber(userData.mobile);
    setClientPassword(userData.password);
    setClientReference(userData.reference);
    setClientLimit(userData.limit);
    setClientMobileShare(userData.mobile_share);
    setClientCommission(userData.commission);
    setClientMatchCommission(userData.match_commission);
    setClientSessionCommission(userData.session_commission);
    setClientCassinoCommission(userData.cassino_commission);
    setClientCassinoStatus(userData.cassino_status === 1 ? true : false);

    const payload = {
      acc_type: "agent",
      user_id: userData.parent,
    };

    getAccount(payload, {
      onSuccess: (response) => {
        setParentData(response.data.data);
      },
      onError: (error) => {
        console.log(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const generateNewPassword = () => {
    setClientPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const userId = userData.id;

    const payload = {
      acc_type: "user",
      id: userId,
      code: userData.code,
      reference: clientReference,
      name: clientName,
      mobile: clientNumber,
      password: String(clientPassword),
      parent: userData.parent,
      limit: clientLimit,
      mobile_share: clientMobileShare,
      match_commission: clientMatchCommission,
      commission: clientCommission,
      session_commission: clientSessionCommission,
      cassino_commission: clientCassinoCommission,
      // cassino_status: clientCassinoStatus,
    };

    updateAccount(
      { userId, payload },
      {
        onSuccess: () => {
          toast.success("Client Updated Successfully");
          navigate("/client");
        },
        onError: (error) => {
          console.log(error);
          toast.error("Client Update Failed");
        },
      }
    );
  }

  function handleCancel(e) {
    e.preventDefault();
    navigate("/client");
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Client </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/client">Client</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Client</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="clientName"
                            name="clientName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            value={clientName}
                            onChange={(e) => {
                              setClientName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="clientNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="clientNumber"
                            name="clientNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            value={clientNumber}
                            onChange={(e) => {
                              setClientNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="clientReference"
                            name="clientReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            value={clientReference}
                            onChange={(e) => {
                              setClientReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="clientPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="clientPassword"
                              name="clientPassword"
                              className="form-control"
                              required
                              value={clientPassword}
                              onChange={(e) => {
                                setClientPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div class="accordion" id="accordionExample2">
                  <div class="accordion-item">
                    <h2 class="accordion-header">
                      <button
                        class="accordion-button"
                        id="create-button-2"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      class="accordion-collapse collapse show"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Client Limit</label>
                            <input
                              type="number"
                              id="clientLimit"
                              name="clientLimit"
                              className="form-control"
                              value={clientLimit}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.limit}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.share}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select
                              className="form-select"
                              id="clientCassinoStatus"
                              name="clientCassinoStatus"
                              required
                              disabled={true}
                              value={clientCassinoStatus}
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setClientCassinoStatus(true);
                                } else {
                                  setClientCassinoStatus(false);
                                }
                              }}
                            >
                              <option value={false}>OFF</option>
                              <option
                                value={true}
                                disabled={parentData?.cassino_status === 0}
                              >
                                ON
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="clientCommission"
                              name="clientCommission"
                              required
                              value={clientCommission}
                              onChange={(e) => {
                                setClientCommission(e.target.value);
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Match Commission
                            </label>
                            <input
                              type="number"
                              id="clientMatchCommission"
                              name="clientMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.match_commission}
                              required
                              value={clientMatchCommission}
                              onChange={(e) => {
                                setClientMatchCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Session Commission
                            </label>
                            <input
                              type="number"
                              id="clientSessionCommission"
                              name="clientSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.session_commission}
                              required
                              value={clientSessionCommission}
                              onChange={(e) => {
                                setClientSessionCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="clientCassinoCommission"
                              name="clientCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData?.cassino_commission}
                              required
                              value={clientCassinoCommission}
                              onChange={(e) => {
                                setClientCassinoCommission(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData?.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button type="submit" className="btn btn-success">
                  Edit Client
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
